<!--
* @description 
* @fileName case.vue
* @author 孟建伟
* @date 2020/11/13 10:10:14
* @version V1.1.0
!-->
<template>
  <div class="demo-image__preview">
    <div v-if="imgData.length > 0" class="showImg">
      <div v-for="item in imgData" :key="item">
        <img :src="'http://production.dhcmetis.com/' + item" preview="1" />
      </div>
    </div>
    <div v-else class="noCase">
      <p>暂无病例</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  components: {},
  watch: {
    imgData() {
      this.$previewRefresh();
    }
  },
  computed: {
    ...mapState({
      imgData: state => state.imgData
    })
  },

  methods: {},
  mounted() {
    this.$preview.on("imageLoadComplete", (e, item) => {
      console.log(this.$preview.self);
    });
  }
};
</script>
<style lang="scss" scoped>
.demo-image__preview {
  width: 100%;
  height: 100%;
  padding: 5px;
  .showImg {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      height: 200px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.noCase {
  margin: 50% auto;
  font-size: 20px;
}
</style>
