<!--
* @description 
* @fileName selectRole.vue
* @author 孟建伟
* @date 2020/10/27 09:59:54
* @version V1.1.0
!-->
<template>
  <div id="selectResearch">
    <p class="title">请选择当前你进入DHCMetis的身份</p>
    <div class="patient" @click="goResearch(true)">
      <div>
        <p>我是患者</p>
        <p class="son">这是我的身份</p>
      </div>
      <div style="padding:0">
        <img src="@/assets/img/paient.png" alt="" />
      </div>
    </div>
    <div class="doctor" @click="goResearch(false)">
      <div>
        <p>我是医生</p>
        <p class="son">这是我的身份</p>
      </div>
      <div style="padding:0">
        <img src="@/assets/img/doctor.png" alt="" />
      </div>
    </div>
    <p class="statement" @click="openStatement">免责声明</p>
    <mu-dialog title="免责声明" width="360" :open.sync="openSimple">
      临床研究小神助，致力于为用户提供客观可信赖的临床研究帮助工具，帮助研究者和临床研究参与者高效地完成研究研究数据采集和管理。
      对于您参与临床研究的权利义务事宜，对于您提交的数据如何应用于临床研究，数据隐私如何得到保障，应在临床研究前知情同意过程中充分知悉。
    </mu-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openSimple: false
    };
  },

  components: {},

  computed: {},

  methods: {
    goResearch(value) {
      if (!value && this.$route.path != "/doctor") this.$router.push("/doctor");
      if (value && this.$route.path != "/doctor") this.$router.push("/patient");
    },
    closeSimpleDialog() {},
    openStatement() {
      this.openSimple = true;
    }
  },
  watch: {},
  mounted() {
    this.$Token();
  }
};
</script>
<style lang="scss" scoped>
#selectResearch {
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  .title {
    font-size: 16px;
    margin-bottom: 60px !important;
  }
  > div {
    width: 300px;
    height: 90px;
    margin: 20px auto;
    border-radius: 10px;
    border: 2px solid #fef1f5;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .patient,
  .doctor {
    padding: 5px 15px;
    box-sizing: border-box;
    div {
      width: 40%;
      padding: 5px;
      padding-top: 10px;
      p {
        font-size: 16px;
        margin-bottom: 18px !important;
        text-align: left;
        font-weight: 500 !important;
      }
      .son {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: normal !important;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .doctor {
    border: 2px solid #ebf7f5;
  }
  .statement {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}
</style>
