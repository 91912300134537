<!--
* @description 访视列表
* @fileName VisitList.vue
* @author 孟建伟
* @date 2020/10/27 15:26:10
* @version V1.1.0
!-->
<template>
  <div class="researchList item">
    <mu-list class="menuList" v-if="visitList.length > 0">
      <mu-list-item
        button
        :ripple="false"
        v-for="(item, index) in visitList"
        :key="index"
        @click="go(item)"
      >
        <mu-list-item-action>
          <svg class="menuIcon alSvgIcon" aria-hidden="true">
            <use xlink:href="#icon-biaoqian"></use>
          </svg>
        </mu-list-item-action>
        <mu-list-item-content>
          <mu-list-item-title>{{ item.name }}</mu-list-item-title>
        </mu-list-item-content>
        <mu-list-item-action>
          <i class="iconfont icon-xiangyou1"></i>
        </mu-list-item-action>
      </mu-list-item>
    </mu-list>
    <div class="noData" v-else>
      <img src="@/assets/img/visit.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      visitList: state => state.visitList
    })
  },

  methods: {
    go(item) {
      const data = {
        code: item.code,
        versionCode: item.versionCode,
        registryEntityCode: item.registryEntityCode
      };
      sessionStorage.setItem("formItem", JSON.stringify(data));
      this.$store.dispatch("getFormListActions", data);
      this.$router.push("/form");
    }
  },
  watch: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.researchList {
  padding: 10px 10px;
  .addreSearch {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 30px;
    right: 10px;
    border-radius: 50%;
    font-size: 25px;
    background: #2985b0;
    color: #fff;
  }
  .list {
    line-height: 30px;
    font-size: 14px;
  }
}
</style>
