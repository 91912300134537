<!--
* @description 
* @fileName addForm.vue
* @author 孟建伟
* @date 2020/11/18 10:04:30
* @version V1.1.0
!-->
<template>
  <div id="addfromDetails">
    <mu-form
      :model="form"
      ref="form"
      class="mu-demo-form"
      :label-position="labelPosition"
      label-width="90"
    >
      <div v-for="(item, index) in ddMap" :key="index">
        <mu-form-item
          v-if="item.controlType === 'TB'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <span slot="label">{{ item.ddName }}</span>
          <!-- 输入框 -->
          <mu-text-field v-model="item.value"></mu-text-field>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'SEL_SIG'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 下拉框 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-select v-model="item.value" error-text>
            <mu-option
              v-for="(option, index) in item.itemOptionList"
              :key="index"
              :label="option.displayName"
              :value="option.value"
            ></mu-option>
          </mu-select>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'RB'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 单选框 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-flex class="select-control-row radioStyle">
            <mu-radio
              :value="option.displayName"
              v-model="item.value"
              v-for="(option, index) in item.itemOptionList"
              :key="index"
              :label="option.displayName"
            ></mu-radio>
          </mu-flex>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'CB'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 多选框 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-checkbox
            :value="option.displayName"
            v-model="item.value"
            v-for="(option, index) in item.itemOptionList"
            :key="index"
            :label="option.displayName"
          ></mu-checkbox>
        </mu-form-item>
        <mu-form-item v-if="item.controlType === 'TA'">
          <!-- 多行文本 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-text-field
            multi-line
            :rows="3"
            :rows-max="6"
            v-model="item.value"
          ></mu-text-field>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'DP'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 日期 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-date-input
            v-model="item.value"
            placeholder="请选择日期"
            container="dialog"
            label-float
            full-width
          ></mu-date-input>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'DTP'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 日期和时间 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-date-input
            v-model="item.value"
            placeholder="请选择日期和时间"
            type="dateTime"
            container="dialog"
            label-float
            full-width
            landscape
          ></mu-date-input>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'TP'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 时间 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-date-input
            clock-type="24hr"
            v-model="item.value"
            type="time"
            format="HH:mm"
            view-type="list"
            label-float
            full-width
            container="dialog"
          ></mu-date-input>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'NTX'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- <mu-date-input  placeholder="请选择时间" value-format="HH:mm:ss" v-model="t" v-if="item.controlType === 'TP'"  view-type="list" type="time" container="dialog" label-float   full-width></mu-date-input> -->
          <!-- 数字题 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-text-field
            v-model="item.value"
            placeholder="请输入数字"
            view-type="list"
          ></mu-text-field>
        </mu-form-item>
        <!-- 文件上传 -->
        <!-- <el-upload
                    v-if="item.controlType === 'SUF'"
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    list-type="picture"
                    :file-list="item.value"
                    >
                </el-upload> -->
        <!-- 地址 -->
        <mu-form-item
          v-if="item.controlType === 'ADSEL'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <span slot="label">{{ item.ddName }}</span>
          <mu-select v-model="province" @change="setCity">
            <mu-option
              v-for="(option, index) in AreaOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></mu-option>
          </mu-select>
          <mu-select v-model="city" @change="setArea">
            <mu-option
              v-for="(option, index) in firstChild"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></mu-option>
          </mu-select>
          <mu-select v-model="area">
            <mu-option
              v-for="(option, index) in twoChild"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></mu-option>
          </mu-select>
        </mu-form-item>
        <mu-form-item
          v-if="item.controlType === 'LB'"
          :prop="'ddMap.' + index + '.value'"
          :rules="itemRules(item)"
        >
          <!-- 文本标题 -->
          <span slot="label">{{ item.ddName }}</span>
          <mu-text-field v-model="item.value"></mu-text-field>
          <!-- <mu-radio v-model="item.value"  :key="index" :value="option.value" :label="option.displayName"></mu-radio> -->
        </mu-form-item>
      </div>
      <mu-button
        class="btn"
        @click="onSubmit"
        data-mu-loading-size="24"
        v-show="!loading"
        v-loading="btnLoading"
        >提交</mu-button
      >
    </mu-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { regionDataPlus, CodeToText } from "element-china-area-data";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      ddList: [],
      form: {},
      btnLoading: false,
      loading: false,
      labelPosition: "left",
      firstChild: [],
      AreaOptions: regionDataPlus,
      twoChild: [],
      city: "", //市
      area: "", //区
      province: "", //省
      value: "",
      showArea: false,
      areaList: {}
    };
  },

  components: { Toast: Toast.name },

  computed: {
    ...mapState({
      ddMap: state => state.ddMap,
      formDetails: state => state.formDetails
    }),
    itemRules(item) {
      return item => {
        let message = "";
        if (item.isImExport === 1) {
          let rules = [
            {
              validate: (value, model) => {
                value = item.value;
                return !!value;
              },
              message: `${item.ddName}不能为空`
            }
          ];
          return rules;
        } else {
          let rules = [];
          if (item.controlType === "NTX") {
            let msg = "";
            rules = [
              {
                validate: (value, model) => {
                  value = item.value;
                  let np = /^(-)?\d+(\.\d+)?$/;
                  return np.test(value);
                },
                message: "输入的数字有误"
              }
            ];
            let nc = item.numConfig;
            if (nc) {
              nc = JSON.parse(nc);
              let rule = {
                validate: (value, model) => {},
                message: ""
              };
              if (nc.allowDecimal == 0) {
                rule.validate = (value, model) => {
                  value = item.value;
                  return value.toString().indexOf(".") === -1;
                };
                rule.message = "不允许输入小数";
              } else {
                rule.validate = (value, model) => {
                  value = item.value;
                  if (value.toString().indexOf(".") >= 0) {
                    let isTrue =
                      value.toString().split(".")[1].length > nc.decimalDigits;
                  }
                  return !isTrue;
                };
                rule.message = `仅允许${nc.nc.decimalDigits}位小数`;
              }
              rules.push(rule);
            }
          }
          return rules;
        }
      };
    },
    paramsData() {
      let list = [];
      let ddList = JSON.parse(JSON.stringify(this.ddMap));
      ddList.forEach(item => {
        if (item.controlType === "CB") item.value = item.value.join(",");
        if (item.controlType === "TP") {
          let date = new Date(item.value);
          item.value = date.getHours() + ":" + date.getMinutes();
        }
        if (item.controlType !== "SUF") {
          list.push({
            name: item.ddCode,
            value: item.value,
            pkId: item.pkId,
            groupCode: item.groupCode,
            exampleId: item.exampleId
          });
        }
      });
      return {
        sections: [
          {
            exampleId: this.formDetails.exampleId,
            parentExampleId: this.formDetails.parentExampleId,
            sectionCode: this.formDetails.code,
            sectionParentCode: this.formDetails.parentCode,
            registyEntityCode: this.formDetails.registryEntityCode,
            patientId: this.formDetails.patientId,
            mode: "update",
            ddValueList: list,
            formType: this.formDetails.selectionType
          }
        ]
      };
    }
  },

  methods: {
    onSubmit() {
      this.btnLoading = true;
      this.$refs.form.validate().then(async result => {
        if (result) {
          try {
            let result = await this.$API.saveFrom(this.paramsData);
            if (result.code === 200) {
              this.$toast.success("提交成功");
              let item = JSON.parse(sessionStorage.getItem("item"));
              if (item) this.$store.dispatch("getDetailsActions", item);
              this.$router.push("/details");
            }
          } catch (error) {
            //  this.$toast.error('提交失败，请重新尝试');
          }
        } else {
        }
        this.btnLoading = false;
      });
    }
  },
  watch: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
#addfromDetails {
  padding: 10px;
}
::v-deep .mu-checkbox {
  display: block;
  width: 100%;
}
</style>
