<!--
* @description 技术支持
* @fileName support.vue
* @author 孟建伟
* @date 2020/10/29 10:16:13
* @version V1.1.0
!-->
<template>
  <div class="support item">
    <div class="content">
      <h3>技术支持</h3>
      <p>edcsupport@dchearth.com</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {},
  watch: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.support {
  background: #ededed;
  padding-top: 100px;
}
.content {
  background: #ededed;
  h3 {
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
  }
}
</style>
