<!--
* @description 
* @fileName patientHome.vue
* @author 孟建伟
* @date 2020/10/27 10:03:26
* @version V1.1.0
!-->
<template>
  <div class="researchList item">
    <div class="addreSearch" @click="addSearch">
      <i class="iconfont icon-chuangjian"></i>
    </div>
    <mu-list textline="two-line" v-if="list.length > 0">
      <mu-list-item
        button
        :ripple="false"
        v-for="(item, index) in list"
        :key="index"
        @click="go(item)"
      >
        <mu-list-item-content>
          <mu-list-item-title>{{ item.researchName }}</mu-list-item-title>
          <mu-list-item-sub-title>{{ item.code }}</mu-list-item-sub-title>
        </mu-list-item-content>
        <mu-list-item-action>
          <i class="iconfont icon-xiangyou1"></i>
        </mu-list-item-action>
      </mu-list-item>
    </mu-list>
    <div class="noData" v-if="list.length === 0">
      <img src="@/assets/img/reSearch.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },

  components: {},

  computed: {},

  methods: {
    go(item) {
      const data = {
        researchcode: item.researchCode,
        versionCode: item.versionCode
      };
      this.$store.dispatch("getVisitListActions", data);
      this.$router.push("/visit");
    },
    //添加研究
    addSearch() {
      this.$router.push("/binding");
    }
  },
  watch: {},
  async mounted() {
    this.$Token();
    try {
      let result = await this.$API.getReSearchList();
      if (result.code === 200) {
        this.list = result.data;
      }
    } catch (error) {}
  }
};
</script>
<style lang="scss" scoped>
.researchList {
  padding: 10px 10px;
  .addreSearch {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 30px;
    right: 10px;
    border-radius: 50%;
    font-size: 25px;
    background: #2985b0;
    color: #fff;
    z-index: 3;
  }
  .icon-chuangjian {
    position: absolute;
    left: 10px;
    top: -5px;
  }
}
</style>
