<!--
* @description 表单列表
* @fileName formList.vue
* @author 孟建伟
* @date 2020/10/27 15:26:36
* @version V1.1.0
!-->
<template>
  <div class="researchList item">
    <mu-list class="menuList" v-if="formList.length > 0">
      <mu-list-item
        button
        :ripple="false"
        v-for="(item, index) in formList"
        :key="index"
        @click="goDetails(item)"
      >
        <mu-list-item-action>
          <svg class="menuIcon alSvgIcon" aria-hidden="true">
            <use xlink:href="#icon-biaodan3"></use>
          </svg>
        </mu-list-item-action>
        <mu-list-item-content>
          <mu-list-item-title>{{ item.name }}</mu-list-item-title>
        </mu-list-item-content>
        <mu-list-item-action>
          <i class="iconfont icon-xiangyou1"></i>
        </mu-list-item-action>
      </mu-list-item>
    </mu-list>
    <div class="noData" v-else>
      <img src="@/assets/img/form.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      formList: state => state.formList
    })
  },

  methods: {
    goDetails(item) {
      sessionStorage.setItem("item", JSON.stringify(item));
      this.$store.dispatch("getDetailsActions", item);
      this.$router.push("/details");
    }
  },
  watch: {},
  mounted() {
    this.$Token();
    let itemData = JSON.parse(sessionStorage.getItem("formItem"));
    const data = {
      code: this.$route.query.code || itemData.code,
      versionCode: this.$route.query.versionCode || itemData.versionCode,
      registryEntityCode:
        this.$route.query.registryEntityCode || itemData.registryEntityCode,
      exampleId: this.$route.query.exampleId || null
    };
    this.$store.dispatch("getFormListActions", data);
  }
};
</script>
<style lang="scss" scoped>
.researchList {
  padding: 10px 10px;
  .addreSearch {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 30px;
    right: 10px;
    border-radius: 50%;
    font-size: 25px;
    background: #2985b0;
    color: #fff;
  }
  .list {
    line-height: 30px;
    font-size: 14px;
  }
}
</style>
