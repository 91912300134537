<!--
* @description 绑定研究
* @fileName bindingResearch.vue
* @author 孟建伟
* @date 2020/10/27 15:24:13
* @version V1.1.0
!-->
<template>
  <div class="binding item">
    <mu-form
      :model="form"
      class="mu-demo-form"
      ref="form"
      :label-position="labelPosition"
      label-width="100"
    >
      <mu-form-item label="输入邀请码" prop="code" :rules="codeRules">
        <mu-text-field
          v-model="form.code"
          placeholder="请输入邀请码"
        ></mu-text-field>
      </mu-form-item>
    </mu-form>
    <mu-button class="btn" @click="submit">确认</mu-button>
    <!-- <div class="addreSearch" @click="ScanCode">
        <i class="iconfont icon-saoma"></i>
      </div> -->
    <mu-dialog
      width="300"
      max-width="80%"
      :esc-press-close="false"
      :overlay-close="false"
      transition="slide-top"
      :open.sync="openAlert"
      dialog-class="diaStyle"
    >
      <span slot="title">加入研究</span>
      <ul class="list">
        <li>
          <p>
            研究名称:<span>{{
              searchData.researchName ? searchData.researchName : "暂无"
            }}</span>
          </p>
        </li>
        <li>
          <p>
            中心名称:<span>{{
              searchData.centerName ? searchData.centerName : "暂无"
            }}</span>
          </p>
        </li>
        <li>
          <p>
            受试者编号:<span>{{
              searchData.patientCode ? searchData.patientCode : "暂无"
            }}</span>
          </p>
        </li>
      </ul>
      <mu-button
        slot="actions"
        flat
        color="primary"
        class="left"
        @click="openAlert = false"
        >取消</mu-button
      >
      <mu-button
        slot="actions"
        flat
        color="primary"
        class="right"
        @click="submitReSearch"
        >确定</mu-button
      >
    </mu-dialog>
  </div>
</template>

<script>
import wx from "weixin-jsapi";
export default {
  data() {
    return {
      openAlert: false,
      labelPosition: "left",
      searchData: {},
      codeRules: [{ validate: val => !!val, message: "必须填写用户名" }],
      form: {
        code: ""
      }
    };
  },

  components: {},

  computed: {},

  methods: {
    submit() {
      this.$refs.form.validate().then(async result => {
        if (result) {
          try {
            let message = "";
            let result = await this.$API.bindingReSearch(this.form.code);
            switch (result.code) {
              case 201:
                message = "此患者已被绑定";
                break;
              case 202:
                message = "此邀请码不存在";
                break;
              case 203:
                message = "您已绑定此研究，不可再绑";
                break;
              default:
                break;
            }
            if (result.code === 200) {
              this.searchData = result.data;
              this.openAlert = true;
            } else {
              this.$toast.error(message);
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
      //
    },
    //提交研究
    async submitReSearch() {
      try {
        let result = await this.$API.bindingReSearch(this.form.code);
      } catch (error) {}
      this.openAlert = false;
      this.$router.push("/patient");
    }
  },
  watch: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.binding {
  padding: 10px;
  padding-top: 20px;
  .van-cell-group {
    margin-bottom: 20px;
  }
  .addreSearch {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 30px;
    right: 10px;
    border-radius: 50%;
    font-size: 25px;
    background: #2985b0;
    color: #fff;
  }
}
.diaStyle {
  .list {
    li {
      color: #838383;
      font-size: 12px;
      span {
        margin-left: 10px;
      }
    }
  }
}
</style>
