<!--
* @description 
* @fileName formDetails.vue
* @author 孟建伟
* @date 2020/10/28 15:38:51
* @version V1.1.0
!-->
<template>
  <div class="form item">
    <div class="addreSearch" @click="showSelect = true">
      <i class="iconfont icon-chuangjian"></i>
    </div>
    <mu-form
      :model="form"
      ref="form"
      v-if="!isShowAdd"
      class="mu-demo-form"
      :label-position="labelPosition"
      label-width="120"
    >
      <div v-for="(detail, index) in mapList" :key="index">
        <!-- 单标签 -->
        <div v-if="detail.isRepeat === '0'">
          <div v-for="(item, index) in detail.groupMap" :key="index">
            <div v-if="item.controlType === 'LB' && !item.hidden">
              <p class="title" :style="{ fontSize: item.numConfig }">
                {{ item.ddName }}
              </p>
            </div>
            <!-- 输入框 -->
            <mu-form-item
              v-if="item.controlType === 'TB' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-text-field
                v-model="item.value"
                :suffix="item.unitOfMeasure ? item.unitOfMeasure : ''"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-text-field>
            </mu-form-item>

            <!-- 下拉框 -->
            <mu-form-item
              v-if="item.controlType === 'SEL_SIG' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-select v-model="item.value" error-text>
                <mu-option
                  v-for="(option, index) in item.itemOptionList"
                  :key="index"
                  :label="option.displayName"
                  :value="option.displayName"
                ></mu-option>
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-select>
            </mu-form-item>
            <!-- 单选框 -->
            <mu-form-item
              v-if="item.controlType === 'RB' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-flex class="select-control-row radioStyle">
                <mu-radio
                  :value="option.displayName"
                  v-model="item.value"
                  v-for="(option, index) in item.itemOptionList"
                  :key="index"
                  :label="option.displayName"
                ></mu-radio>
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-flex>
            </mu-form-item>
            <!-- 多选框 -->
            <mu-form-item
              v-if="item.controlType === 'CB' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-checkbox
                :value="option.displayName"
                v-model="item.value"
                v-for="(option, index) in item.itemOptionList"
                :key="index"
                :label="option.displayName"
                @change="getValue"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-checkbox>
            </mu-form-item>
            <!-- 多行文本 -->
            <mu-form-item v-if="item.controlType === 'TA' && !item.hidden">
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-text-field
                multi-line
                :rows="4"
                :rows-max="6"
                v-model="item.value"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-text-field>
            </mu-form-item>
            <!-- 日期 -->
            <mu-form-item
              v-if="item.controlType === 'DP' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-date-input
                v-model="item.value"
                placeholder="请选择日期"
                container="dialog"
                label-float
                full-width
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-date-input>
            </mu-form-item>
            <!-- 日期和时间 -->
            <mu-form-item
              v-if="item.controlType === 'DTP' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-date-input
                v-model="item.value"
                placeholder="请选择日期和时间"
                type="dateTime"
                container="dialog"
                label-float
                full-width
                landscape
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-date-input>
            </mu-form-item>
            <!-- 时间 -->
            <mu-form-item
              v-if="item.controlType === 'TP' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-date-input
                :no-dispaly="true"
                clock-type="24hr"
                v-model="item.value"
                type="time"
                format="HH:mm"
                view-type="list"
                label-float
                full-width
                container="dialog"
                @change="getTime"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-date-input>
            </mu-form-item>
            <!-- 数字题 -->
            <mu-form-item
              v-if="item.controlType === 'NTX' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <mu-text-field
                v-model="item.value"
                placeholder="请输入数字"
                view-type="list"
                :suffix="item.unitOfMeasure ? item.unitOfMeasure : ''"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-text-field>
            </mu-form-item>
            <!-- 地址 -->
            <mu-form-item
              v-if="item.controlType === 'ADSEL' && !item.hidden"
              :prop="'groupMap.' + index + '.value'"
              :rules="itemRules(item)"
            >
              <span slot="label"
                ><i class="red" v-if="item.isImExport === 1">*</i
                >{{ item.ddName }}</span
              >
              <zr-select
                :data-source="AreaOptions"
                :default-value="item.value"
                v-model="item.value"
                @ok="okSelect"
                :display-render="
                  selected => selected.map(item => item.label).join('／')
                "
                :cols="3"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </zr-select>
            </mu-form-item>
          </div>
        </div>
        <!-- 重复组 -->
        <div v-if="detail.isRepeat === '1'">
          <div v-for="(groupItem, num) in detail.groupMap" :key="num">
            <mu-expansion-panel>
              <div slot="header" style="display:flex">
                <div>{{ titleStr(detail.groupName) }}</div>
                <div style="padding-top:1px;margin-left:20px" v-if="num > 0">
                  <span :disabled="groupItem[0].status === 1"></span>
                  <i
                    class="iconfont icon-shanchu"
                    @click.stop="deleteForm(groupItem)"
                  ></i>
                  <!-- <svg class="menuIcon alSvgIcon" aria-hidden="true">
                              <use xlink:href="#icon-jianshao"></use>
                        </svg> -->
                </div>
                <div
                  style="padding-top:1px;margin-left:20px;font-weight:bold"
                  v-if="groupItem"
                >
                  <span>
                    <i
                      class="iconfont icon-jiahao2"
                      @click.stop="addGroup(detail)"
                    ></i>
                  </span>
                  <!-- <svg class="menuIcon alSvgIcon" aria-hidden="true">
                              <use xlink:href="#icon-lvsefenkaicankaoxianban-"></use>
                        </svg> -->
                </div>
                <div style="padding-top:1px;margin-left:20px" v-if="groupItem">
                  <span>
                    <i
                      class="iconfont icon-fankui"
                      @click.stop="editGroup(groupItem, num)"
                    ></i>
                  </span>
                  <!-- <svg class="menuIcon alSvgIcon" aria-hidden="true">
                              <use xlink:href="#icon-xiugai31"></use>
                        </svg> -->
                </div>
              </div>
              <div v-for="(item, key) in groupItem" :key="key">
                <!-- <div v-if="item.controlType==='LB'">
                        <p  class="title"  :style="{fontSize:item.numConfig}">{{item.ddName}}</p>
                      </div> -->
                <!-- 输入框 -->
                <mu-form-item
                  v-if="item.controlType === 'TB' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-text-field v-model="item.value">
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-text-field>
                </mu-form-item>
                <!-- 下拉框 -->
                <mu-form-item
                  v-if="item.controlType === 'SEL_SIG' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-select v-model="item.value" error-text>
                    <mu-option
                      v-for="(option, index) in item.itemOptionList"
                      :key="index"
                      :label="option.displayName"
                      :value="option.value"
                      disabled
                    ></mu-option>
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-select>
                </mu-form-item>
                <!-- 单选框 -->
                <mu-form-item
                  v-if="item.controlType === 'RB' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-flex class="select-control-row radioStyle">
                    <mu-radio
                      :value="option.displayName"
                      v-model="item.value"
                      v-for="(option, index) in item.itemOptionList"
                      :key="index"
                      disabled
                      :label="option.displayName"
                    >
                      <template slot="prepend" v-if="item.ddInputGuide">
                        <mu-tooltip :content="item.ddInputGuide">
                          <i class="iconfont icon-tishi"></i>
                        </mu-tooltip>
                      </template>
                    </mu-radio>
                  </mu-flex>
                </mu-form-item>
                <!-- 多选框 -->
                <mu-form-item
                  v-if="item.controlType === 'CB' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-checkbox
                    :value="option.displayName"
                    v-model="item.value"
                    v-for="(option, index) in item.itemOptionList"
                    :key="index"
                    disabled
                    :label="option.displayName"
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-checkbox>
                </mu-form-item>
                <!-- 多行文本 -->
                <mu-form-item
                  v-if="item.controlType === 'TA' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-text-field
                    multi-line
                    :rows="3"
                    :rows-max="6"
                    v-model="item.value"
                    disabled
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-text-field>
                </mu-form-item>
                <!-- 日期 -->
                <mu-form-item
                  v-if="item.controlType === 'DP' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-date-input
                    v-model="item.value"
                    placeholder="请选择日期"
                    container="dialog"
                    label-float
                    full-width
                    disabled
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-date-input>
                </mu-form-item>
                <!-- 日期和时间 -->
                <mu-form-item
                  v-if="item.controlType === 'DTP' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-date-input
                    v-model="item.value"
                    placeholder="请选择日期和时间"
                    type="dateTime"
                    container="dialog"
                    label-float
                    full-width
                    landscape
                    disabled
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-date-input>
                </mu-form-item>
                <!-- 时间 -->
                <mu-form-item
                  v-if="item.controlType === 'TP' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-date-input
                    clock-type="24hr"
                    :no-display="true"
                    v-model="item.value"
                    type="time"
                    format="HH:mm"
                    view-type="list"
                    label-float
                    full-width
                    disabled
                    container="dialog"
                    @change="getTime"
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                    <template>
                      <div>1111</div>
                    </template>
                  </mu-date-input>
                </mu-form-item>
                <!-- 数字题 -->
                <mu-form-item
                  v-if="item.controlType === 'NTX' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <mu-text-field
                    v-model="item.value"
                    placeholder="请输入数字"
                    view-type="list"
                    disabled
                  >
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-text-field>
                </mu-form-item>
                <!-- 文件上传 -->
                <!-- <el-upload
                        v-if="item.controlType === 'SUF'"
                        class="upload-demo"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        list-type="picture"
                        :file-list="item.value"
                        >
                    </el-upload> -->
                <!-- 地址 -->
                <mu-form-item
                  v-if="item.controlType === 'ADSEL' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label"
                    ><i class="red" v-if="item.isImExport === 1">*</i
                    >{{ item.ddName }}</span
                  >
                  <zr-select
                    :data-source="AreaOptions"
                    :default-value="item.value"
                    v-model="item.value"
                    disabled
                    @ok="okSelect"
                    :display-render="
                      selected => selected.map(item => item.label).join('／')
                    "
                    :cols="3"
                  ></zr-select>
                </mu-form-item>
                <!-- 文本标题 -->
                <mu-form-item
                  v-if="item.controlType === 'LB' && !item.hidden"
                  :readonly="item.dataType == 1"
                >
                  <span slot="label">{{ item.ddName }}</span>
                  <mu-text-field v-model="item.value">
                    <template slot="prepend" v-if="item.ddInputGuide">
                      <mu-tooltip :content="item.ddInputGuide">
                        <i class="iconfont icon-tishi"></i>
                      </mu-tooltip>
                    </template>
                  </mu-text-field>
                </mu-form-item>
              </div>
            </mu-expansion-panel>
          </div>
        </div>
      </div>
      <mu-button
        class="btn submitBtn"
        @click="onSubmit"
        data-mu-loading-size="24"
        v-show="!loading"
        v-loading="btnLoading"
        >提交</mu-button
      >
    </mu-form>

    <mu-slide-bottom-transition>
      <mu-form
        :model="groupform"
        v-if="isShowAdd"
        ref="Groupform"
        class="mu-demo-form"
        :label-position="labelPosition"
        label-width="90"
      >
        <div v-for="(item, index) in ddMap" :key="index">
          <mu-form-item
            v-if="item.controlType === 'TB'"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 输入框 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-text-field
              v-model="item.value"
              :suffix="item.unitOfMeasure ? item.unitOfMeasure : ''"
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-text-field>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'SEL_SIG' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 下拉框 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-select v-model="item.value" error-text>
              <mu-option
                v-for="(option, index) in item.itemOptionList"
                :key="index"
                :label="option.displayName"
                :value="option.value"
              ></mu-option>
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-select>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'RB' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 单选框 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-flex class="select-control-row radioStyle">
              <mu-radio
                :value="option.displayName"
                v-model="item.value"
                v-for="(option, index) in item.itemOptionList"
                :key="index"
                :label="option.displayName"
              >
                <template slot="prepend" v-if="item.ddInputGuide">
                  <mu-tooltip :content="item.ddInputGuide">
                    <i class="iconfont icon-tishi"></i>
                  </mu-tooltip>
                </template>
              </mu-radio>
            </mu-flex>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'CB' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 多选框 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-checkbox
              :value="option.displayName"
              v-model="item.value"
              v-for="(option, index) in item.itemOptionList"
              :key="index"
              :label="option.displayName"
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-checkbox>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'TA' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 多行文本 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-text-field
              multi-line
              :rows="3"
              :rows-max="6"
              v-model="item.value"
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-text-field>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'DP' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 日期 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-date-input
              v-model="item.value"
              placeholder="请选择日期"
              container="dialog"
              label-float
              full-width
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-date-input>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'DTP' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 日期和时间 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-date-input
              v-model="item.value"
              placeholder="请选择日期和时间"
              type="dateTime"
              container="dialog"
              label-float
              full-width
              landscape
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-date-input>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'TP' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 时间 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-date-input
              clock-type="24hr"
              v-model="item.value"
              type="time"
              format="HH:mm"
              view-type="list"
              label-float
              full-width
              container="dialog"
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-date-input>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'NTX' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- <mu-date-input  placeholder="请选择时间" value-format="HH:mm:ss" v-model="t" v-if="item.controlType === 'TP'"  view-type="list" type="time" container="dialog" label-float   full-width></mu-date-input> -->
            <!-- 数字题 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-text-field
              v-model="item.value"
              placeholder="请输入数字"
              view-type="list"
            >
              <template slot="prepend" v-if="item.ddInputGuide">
                <mu-tooltip :content="item.ddInputGuide">
                  <i class="iconfont icon-tishi"></i>
                </mu-tooltip>
              </template>
            </mu-text-field>
          </mu-form-item>
          <!-- 文件上传 -->
          <!-- <el-upload
                          v-if="item.controlType === 'SUF'"
                          class="upload-demo"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          list-type="picture"
                          :file-list="item.value"
                          >
                      </el-upload> -->
          <!-- 地址 -->
          <mu-form-item
            v-if="item.controlType === 'ADSEL' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <zr-select
              :data-source="AreaOptions"
              :default-value="item.value"
              v-model="item.value"
              @ok="okSelect"
              :display-render="
                selected => selected.map(item => item.label).join('／')
              "
              :cols="3"
            ></zr-select>
          </mu-form-item>
          <mu-form-item
            v-if="item.controlType === 'LB' && !item.hidden"
            :readonly="item.dataType == 1"
            :prop="'ddMap.' + index + '.value'"
            :rules="itemRules(item)"
          >
            <!-- 文本标题 -->
            <span slot="label"
              ><i class="red" v-if="item.isImExport === 1">*</i
              >{{ item.ddName }}</span
            >
            <mu-text-field v-model="item.value"></mu-text-field>
            <!-- <mu-radio v-model="item.value"  :key="index" :value="option.value" :label="option.displayName"></mu-radio> -->
          </mu-form-item>
        </div>
        <mu-button
          class="btn"
          @click="addSubmit"
          data-mu-loading-size="24"
          v-show="!loading"
          v-loading="btnLoading"
          >提交</mu-button
        >
      </mu-form>
    </mu-slide-bottom-transition>
    <mu-bottom-sheet :open.sync="showSelect">
      <div class="bottom">
        <div class="left">
          <img
            src="@/assets/img/xingzhuang@2x.png"
            alt=""
            @click="onSelectPhoto"
          />
          <p>上传</p>
          <!-- <form action="RequestServlet" method="post" enctype="application/x-www-form-urlencoded">  -->
          <input
            ref="filElem"
            @change="handleFileChange"
            id="file"
            style="display:none;"
            class="js_upFile cover1"
            type="file"
            name="cover"
            accept="image/*"
            multiple
          />
          <!-- </form> -->
        </div>
        <div class="right" @click="lookcase">
          <img src="@/assets/img/bingli.png" alt="" />
          <p>病例</p>
        </div>
      </div>
    </mu-bottom-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { regionData, CodeToText } from "element-china-area-data";
import { SAVEIMG, SAVEADDDATA } from "../../store/mutations-type";
import { Select } from "zarm-vue";
export default {
  components: { zrSelect: Select },
  data() {
    return {
      username: "",
      radio: "",
      t: "",
      loading: true,
      isShowAdd: false,
      btnLoading: false,
      time: undefined,
      labelPosition: "left",
      showCalendar: false,
      showSelect: false,
      showPicker: false,
      showArea: false,
      showTime: false,
      AreaOptions: regionData,
      firstChild: [],
      twoChild: [],
      handleShow: {}, //控制显示隐藏
      sectionDDMap: [],
      address: [
        {
          city: "", //市
          area: "", //区
          province: "" //省
        }
      ],
      value: "",
      showArea: false,
      areaList: {},
      option: [1, 2, 3, 4, 5],
      ddList: [],
      mapList: [],
      ddMap: [],
      map: [],
      selectionHidden: {},
      form: {},
      groupform: {},
      groupCode: "",
      isAdd: true
    };
  },
  computed: {
    ...mapState({
      detailsData: state => state.detailsData,
      formDetails: state => state.formDetails
    }),
    //动态计算表单验证
    itemRules(item) {
      return item => {
        let message = "";
        let value = item.value;
        let rules = [];
        if (item.isImExport === 1) {
          rules.push({
            validate: (v, model) => {
              v = value;
              return !!v;
            },
            message: `${item.ddName}不能为空`
          });
        }
        if (item.controlType === "NTX") {
          if (value) {
            rules.push({
              validate: (v, model) => {
                v = value;
                let np = /^(-)?\d+(\.\d+)?$/;
                return np.test(v);
              },
              message: "输入的数字有误"
            });
            let nc = JSON.parse(item.numConfig);
            if (nc) {
              if (nc.allowDecimal == 0) {
                rules.push({
                  validate: (v, model) => {
                    v = value;
                    return v.toString().indexOf(".") === -1;
                  },
                  message: "不允许输入小数"
                });
              } else {
                if (
                  value.toString().indexOf(".") >= 0 &&
                  value.toString().split(".")[1].length > nc.decimalDigits
                ) {
                  rules.push({
                    validate: (v, model) => {
                      v = value;
                      return false;
                    },
                    message: "仅允许" + nc.decimalDigits + "位小数"
                  });
                }
              }
              if (
                nc.scopeValid == 1 &&
                (parseFloat(value) > parseFloat(nc.scopeRange_up) ||
                  parseFloat(value) < parseFloat(nc.scopeRange_down))
              ) {
                rules.push({
                  validate: (v, model) => {
                    v = value;
                    return false;
                  },
                  message:
                    "数值范围输入有误! 数值范围:{" +
                    nc.scopeRange_down +
                    "," +
                    nc.scopeRange_up +
                    "}"
                });
              }
            }
          }
        }
        return rules;
      };
    },
    //添加请求参数
    paramsData() {
      let list = [];
      let mapList = JSON.parse(JSON.stringify(this.mapList));
      mapList.map(item => {
        if (item.isRepeat == 0) {
          Object.values(item.groupMap).map(e => {
            if (e.controlType === "CB") {
              e.value ? (e.value = e.value.join(",")) : (e.value = []);
            }
            if (e.controlType === "TP") {
              let date = new Date(e.value);
              e.value = date.getHours() + ":" + date.getMinutes();
            }
            if (e.controlType === "ADSEL") {
              e.value ? (e.value = e.value.join(",")) : (e.value = []);
            }
            if (e.controlType !== "SUF") {
              list.push({
                name: e.ddCode,
                value: e.value,
                pkId: e.pkId,
                groupCode: e.groupCode,
                exampleId: e.exampleId
              });
            }
          });
        }
      });
      return {
        sections: [
          {
            exampleId: this.formDetails.exampleId,
            parentExampleId: this.formDetails.parentExampleId,
            sectionCode: this.formDetails.code,
            sectionParentCode: this.formDetails.parentCode,
            patientId: this.formDetails.patientId,
            mode: "create",
            registyEntityCode: this.formDetails.registryEntityCode,
            ddValueList: list,
            formType: this.formDetails.selectionType
          }
        ]
      };
    },
    //重复组参数
    groupData() {
      let list = [];
      let ddList = JSON.parse(JSON.stringify(this.ddMap));
      Object.values(ddList).forEach(item => {
        if (item.controlType === "CB") item.value = item.value.join(",");
        if (item.controlType === "TP") {
          let date = new Date(item.value);
          item.value = date.getHours() + ":" + date.getMinutes();
        }
        if (item.controlType === "ADSEL") {
          item.value ? (item.value = item.value.join(",")) : (item.value = []);
        }
        if (item.controlType !== "SUF") {
          list.push({
            name: item.ddCode,
            value: item.value,
            pkId: item.pkId,
            groupCode: item.groupCode,
            exampleId: item.exampleId,
            status: item.status
          });
        }
      });
      return {
        sections: [
          {
            exampleId: this.formDetails.exampleId,
            parentExampleId: this.formDetails.parentExampleId,
            sectionCode: this.formDetails.code,
            groupCode: this.groupCode,
            sectionParentCode: this.formDetails.parentCode,
            registyEntityCode: this.formDetails.registryEntityCode,
            patientId: this.formDetails.patientId,
            mode: this.isAdd ? "CREATE" : "UPDATE",
            ddValueList: list,
            formType: "Multiple"
          }
        ],
        submit: 0
      };
    }
  },
  watch: {
    detailsData: {
      deep: true,
      handler(newValue, oldValue) {
        this.mapList = JSON.parse(JSON.stringify(newValue.ddMap));
        this.mapList.forEach(item => {
          item.groupMap = [...Object.values(item.groupMap)];
        });
        this.loading = false;
      }
    },
    mapList: {
      deep: true,
      handler(n, o) {
        n.forEach(item => {
          Object.values(item.groupMap).forEach(detail => {
            this.handleVisible(detail);
          });
        });
      }
    },
    ddMap: {
      deep: true,
      handler(n, o) {
        Object.values(n).forEach(item => {
          this.handleVisible(item);
        });
      }
    }
  },
  methods: {
    getValue(value) {
      console.log(value);
    },
    //修改重复组
    editGroup(value, index) {
      console.log(value);
      this.isAdd = false;
      this.ddMap = value;
      this.isShowAdd = true;
    },
    //删除组
    async deleteForm(data) {
      let { exampleId } = data[0];
      try {
        let result = await this.$API.deleteGroup({ exampleId });
        if (result.code === 200) {
          this.$toast.success("删除成功");
          this.$store.dispatch("getDetailsActions", this.formDetails);
        } else {
          this.$toast.error("删除失败");
        }
      } catch (error) {
        console.log(error);
      }
    },
    //添加组
    async addGroup(item) {
      this.isAdd = true;
      this.groupCode = item.groupCode;
      const data = {
        groupCode: item.groupCode,
        code: this.formDetails.code,
        parentCode: this.formDetails.parentCode,
        exampleId: this.formDetails.exampleId,
        parentExampleId: this.formDetails.parentExampleId,
        sectionCode: this.formDetails.code,
        sectionParentCode: this.formDetails.parentCode,
        registryEntityCode: this.formDetails.registryEntityCode,
        patientId: this.formDetails.patientId,
        selectionType: this.formDetails.selectionType
      };
      try {
        let result = await this.$API.addForm(data);
        if (result.code === 200) {
          this.isShowAdd = true;
          let { ddMap } = result.data;
          Object.values(ddMap).map(item => {
            if (
              item.controlType === "CB" ||
              item.controlType === "ADSEL" ||
              item.controlType === "SUF"
            )
              item.value
                ? (item.value = item.value.split(","))
                : (item.value = []);
            if (item.controlType === "TP") {
              if (item.value) {
                let time = "2020-11-10 " + v.value;
                item.value = new Date(time);
              }
            }
            item.hidden = item.visible == 0;
          });
          this.ddMap = ddMap;
        }
      } catch (error) {
        console.log(error);
      }
    },
    lookcase() {
      this.$router.push("/case");
    },
    //重复组新增提交
    addSubmit() {
      let ddList = JSON.parse(JSON.stringify(this.mapList));
      this.btnLoading = true;
      this.$refs.Groupform.validate().then(async result => {
        if (result) {
          try {
            let result = await this.$API.saveFrom(this.groupData);
            if (result.code === 200) {
              //将maplist数组格式转化为后台传过来的对象格式
              ddList.map((v, k) => {
                if (v.isRepeat === "0") {
                  v.groupMap = { [v.groupMap[0].ddCode]: v.groupMap[0] };
                } else if (v.isRepeat === "1") {
                  let group = {};
                  v.groupMap.reduce((acc, cur, index) => {
                    acc[v.groupCode + index] = cur;
                    group = {
                      ...group,
                      ...acc
                    };
                    return acc;
                  }, {});
                  v.groupMap = group;
                }
              });
              localStorage.setItem("mapList", JSON.stringify(ddList));
              this.$toast.success("提交成功");
              let item = JSON.parse(sessionStorage.getItem("item"));
              if (item) this.$store.dispatch("getDetailsActions", item);
              this.loading = false;
              this.isShowAdd = false;
            } else {
              this.$toast.error("提交失败，请重新尝试");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
        }
        this.btnLoading = false;
      });
    },
    async handleFileChange(e) {
      let file = Array.from(e.target.files);
      let list = [];
      var forms = new FormData();
      file.forEach((item, i) => {
        if (item) {
          forms.append("file", item);
          list.push(forms);
        }
      });
      this.showSelect = false;
      try {
        let result = await this.$API.upload(
          forms,
          this.formDetails.code,
          this.formDetails.patientId
        );
        if (result.code === 200) {
          console.log(result.data);
          this.$toast.success("恭喜你，图片上传成功");
          this.$store.commit(SAVEIMG, result.data);
        }
      } catch (error) {}
    },
    //截取字符串
    titleStr(str) {
      let index = str.lastIndexOf("(");
      if (index != -1) {
        str = str.replace(str.substring(index, str.length), "");
      }
      return str;
    },
    onSelectPhoto() {
      this.$refs.filElem.dispatchEvent(new MouseEvent("click"));
    },
    okSelect(value) {
      console.log(value);
    },
    onSubmit() {
      this.btnLoading = true;
      this.$refs.form.validate().then(async result => {
        if (result) {
          try {
            console.log(333333333);
            let result = await this.$API.saveFrom(this.paramsData);
            if (result.code === 200) {
              this.$toast.success("提交成功");
              this.$router.go(-1);
              this.btnLoading = false;
            }
          } catch (error) {
            console.log(this.paramsData);
            this.$toast.error("提交失败，请重新尝试");
            this.btnLoading = false;
          }
        } else {
          this.btnLoading = false;
        }
      });
    },
    unique(arr) {
      return arr.reduce(
        (prev, cur) => (prev.includes(cur) ? prev : [...prev, cur]),
        []
      );
    },
    handleVisible(item) {
      let ddCode = item.ddCode;
      const reg = /#(.+?)#/g;
      let np = /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/;
      this.mapList.map(detail => {
        Object.values(detail.groupMap).map(e => {
          let expression = "";
          if (e.conditionExpression) {
            expression = e.conditionExpression.match(reg)[0];
          }
          if (expression.slice(1, expression.length - 1) === ddCode) {
            if (item.value) {
              if (e.operator === "{}" || e.operator === "{/}") {
                let newValue = e.conditionExpression.substring(
                  0,
                  e.conditionExpression.indexOf(".")
                );
                let arr = eval("(" + newValue + ")"); //将数组字符串转化为数组
                let intersect = [];
                let isShow = false;
                //value为数组类型直接取交集
                if (item.controlType === "CB" || item.controlType === "ADSEL") {
                  intersect = item.value.filter(v => arr.indexOf(v) > -1);
                } else {
                  //value为字符串类型直接查找
                  if (arr.indexOf(item.value) > -1) {
                    isShow = true;
                  }
                }
                if (intersect.length > 0 || isShow) {
                  e.hidden = false;
                } else {
                  e.hidden = true;
                }
              } else if (e.operator === "[]") {
                const exprossionArr = e.conditionExpression.split("&&");
                const leftData = exprossionArr[0].match(reg)[0];
                const exprossion = exprossionArr.map(v => {
                  return v.replace(leftData, item.value);
                });
                e.hidden = !eval(exprossion[0]) && eval(exprossion[1]);
              } else {
                let value = item.value;
                if (
                  item.controlType === "DP" ||
                  item.controlType === "DTP" ||
                  item.controlType === "TP"
                ) {
                  let date = new Date(value);
                  if (item.controlType === "DP")
                    value =
                      date.getFullYear() +
                      "-" +
                      (date.getMonth() + 1) +
                      "-" +
                      date.getDate();
                  if (item.controlType === "DTP")
                    value =
                      date.getFullYear() +
                      "-" +
                      (date.getMonth() + 1) +
                      "-" +
                      date.getDate() +
                      " " +
                      date.getHours() +
                      ":" +
                      date.getMinutes();
                }
                if (!np.test(value)) value = "'" + value + "'";
                e.hidden = !eval(
                  e.conditionExpression.replace(expression, value)
                );
              }
            }
          }
        });
      });
    },
    getTime(time) {
      console.log(time);
    }
  },
  mounted() {
    localStorage.removeItem("mapList");
  },
  destroyed() {
    localStorage.removeItem("mapList");
  }
};
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.group {
  border: 2px solid #cccccc;
  padding-right: px;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}
.submitBtn {
  position: fixed;
  width: 98%;
  bottom: 20px;
  left: 1%;
}
.form {
  padding: 10px 10px;
  padding-bottom: 80px;
  box-sizing: border-box;
  .submit {
    background: #273549;
    color: #fff;
    border-radius: 5px;
    height: 35px;
  }
  .addreSearch {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 70px;
    right: 10px;
    border-radius: 50%;
    font-size: 25px;
    background: #2985b0;
    color: #fff;
    z-index: 3;
  }
}
.content {
  padding: 16px 16px 30px;
  display: flex;
  justify-content: space-around;
  div {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 90%;
    }
  }
}
::v-deep .van-field {
  display: flex;
  flex-direction: column;
  background: #ededed;
  margin-bottom: 0 !important;
  padding-bottom: 0;
  input {
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    padding: 5px !important;
    box-sizing: border-box;
    height: 35px;
    background: #fff;
  }
  .van-cell__title {
    margin-bottom: 5px;
    padding-left: 3px;
    font-size: 16px;
  }
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.icon-chuangjian {
  position: absolute;
  left: 10px;
  top: -5px;
}
.bottom {
  height: 150px;
  display: flex;
  padding: 10px 30px;
  padding-top: 30px;
  box-sizing: border-box;
  div {
    flex: 1;
    text-align: center;
    img {
      display: inline-block;
      width: 70px;
      height: 70px;
    }
  }
}
.title {
  text-align: left;
  padding-left: 8px !important;
}
::v-deep .mu-checkbox {
  display: block;
  width: 100%;
}
.addbtn {
  width: 100%;
  color: #000;
}
.delete {
  height: 20px;
  position: absolute;
  right: 0;
  top: 10px;
}
.iconbtn {
  border: none;
  background: none;
}
.js_upFile {
  width: 100%;
  height: 100%;
}
.icon-tishi {
  margin-right: 10px;
}
</style>
