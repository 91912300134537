<!--
* @description 
* @fileName Recruit.vue
* @author 孟建伟
* @date 2020/12/04 16:31:30
* @version V1.1.0
!-->
<template>
  <div id="recruit">
    <mu-form
      ref="form"
      :model="validateForm"
      class="mu-demo-form"
      label-width="120"
      :label-position="labelPosition"
    >
      <mu-form-item label="选择中心" :rules="centerRules" prop="centerKey">
        <mu-select v-model="validateForm.centerKey" prop="centerKey">
          <mu-option
            v-for="(option, index) in center"
            :key="index"
            :label="option.centerName"
            :value="option.centerKey"
          ></mu-option>
        </mu-select>
      </mu-form-item>
      <mu-form-item
        :label="item.previewMessages"
        v-for="(item, index) in validateForm.formList"
        :key="index"
        :rules="itemRules(item)"
        :prop="'formList.' + index + '.value'"
      >
        <mu-text-field
          v-model="item.value"
          v-if="item.type === 1"
          :prop="'formList.' + index + '.value'"
        ></mu-text-field>
        <mu-text-field
          v-model="item.value"
          v-if="item.type === 4"
          :prop="'formList.' + index + '.value'"
        ></mu-text-field>
        <mu-select
          v-model="item.value"
          error-text
          v-if="item.type === 2"
          :prop="'formList.' + index + '.value'"
        >
          <mu-option
            v-for="(option, index) in item.list"
            :key="index"
            :label="option"
            :value="option"
          ></mu-option>
        </mu-select>
        <mu-date-input
          v-if="item.type === 3"
          :prop="'formList.' + index + '.value'"
          v-model="item.value"
          placeholder="请选择日期"
          container="dialog"
          label-float
          full-width
        >
        </mu-date-input>
      </mu-form-item>
      <mu-button
        class="btn"
        @click="addSubmit"
        data-mu-loading-size="24"
        v-show="!loading"
        v-loading="btnLoading"
        >提交</mu-button
      >
    </mu-form>
  </div>
</template>

<script>
import { RecruitForm, getCenter, RecruitAdd } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      validateForm: {
        formList: [],
        centerKey: ""
      },
      btnLoading: false,
      loading: false,
      center: "",
      usernameRules: [],
      labelPosition: "left",
      center: [],
      researchCode: "",
      centerRules: [{ validate: val => !!val, message: "必须选择中心" }]
    };
  },
  computed: {
    params() {
      this.validateForm.formList.map(item => {
        if (item.type === 3 && item.value) {
          // item.value = new Date(item.value)
          console.log(item.value);
        }
      });
      const data = {
        centerCode: this.validateForm.centerKey,
        formList: this.validateForm.formList
      };
      return data;
    }
  },
  watch: {},
  mounted() {
    this.$Token();
    let { researchCode } = this.$route.query;
    this.researchCode = researchCode;
    // this.researchCode = 'w52969tg9mttsv2u'
    if (researchCode) {
      this.getForm(this.researchCode);
      this.getCenterList(this.researchCode);
    }
  },
  methods: {
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    /**
     * 获取表单数据
     *  researchCode： 研究code
     */
    getForm(researchCode) {
      RecruitForm(researchCode)
        .then(res => {
          this.validateForm.formList = res.data.formList;
        })
        .catch(error => {});
    },
    getCenterList(researchCode) {
      getCenter(researchCode)
        .then(res => {
          this.center = res.data;
        })
        .catch(res => {});
    },
    itemRules(item) {
      let rules = [];
      let rule = {
        validate: val => !!val,
        message: `必须填写${item.previewMessages}`
      };
      if (item.type === 4) {
        rules.push({
          validate: val => {
            let np = /^(-)?\d+(\.\d+)?$/;
            return np.test(val);
          },
          message: "输入的数字有误"
        });
      }
      rules.push(rule);
      return rules;
    },
    addSubmit() {
      this.btnLoading = true;
      this.$refs.form.validate().then(result => {
        if (result) {
          RecruitAdd(this.params).then(res => {
            this.$toast.success("编辑成功");
            this.btnLoading = false;
            this.getForm(this.researchCode);
            this.getCenterList(this.researchCode);
          });
        } else {
          this.btnLoading = false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#recruit {
  padding: 10px;
  padding-bottom: 50px;
}
.btn {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 96%;
  margin-left: 2%;
}
/deep/.mu-form {
  padding-bottom: 20px;
}
</style>
