<!--
* @description 
* @fileName not.vue
* @author 孟建伟
* @date 2020/10/27 10:02:20
* @version V1.1.0
!-->
<template>
  <div id="not">
    <div class="tip">
      <img src="@/assets/img/not.png" alt="" />
      <p>即将上线，敬请期待！</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {},
  watch: {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
#not {
  height: 100%;
  .tip {
    height: 200px;
    width: 220px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    img {
      display: block;
      width: 100%;
      height: 80%;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      font-weight: 400 !important;
    }
  }
}
</style>
