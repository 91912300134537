<!--
* @description 
* @fileName myDate.vue
* @author 孟建伟
* @date 2020/10/28 17:26:20
* @version V1.1.0
!-->
<template>
  <div class="myDate">
    <Calendar
      @choseDay="clickDay"
      @changeMonth="changeDate"
      :markDate="timeArr"
      class="date"
    ></Calendar>
    <mu-slide-left-transition>
      <div v-show="isShowSearch">
        <div class="details" v-for="(item, index) in details" :key="index">
          <h3>访视提醒</h3>
          <p>{{ item.researchName }}</p>
          <p>{{ item.name }}</p>
          <div class="remind">
            <p style="color:#838383">访视提醒：</p>
            <ul>
              <li class="van-ellipsis">{{ item.messageContent }}</li>
              <li v-for="(detail, deIndex) in item.msgSettings" :key="deIndex">
                <svg class="menuIcon alSvgIcon" aria-hidden="true">
                  <use
                    xlink:href="#icon-duide"
                    v-if="detail.choice === 1"
                  ></use>
                  <use xlink:href="#icon-right" v-else></use>
                </svg>
                <span>{{ detail.fieldName }}</span>
              </li>
            </ul>
          </div>
          <div class="epro">
            EPRO表单：<span
              v-for="(formItem, formIndex) in item.subList"
              :key="formIndex"
            >
              {{ formItem.name }}
            </span>
          </div>
          <div class="look" @click="goDetails">
            <span>查看详情</span>
            <i class="iconfont icon-xiangyou1"></i>
          </div>
        </div>
      </div>
    </mu-slide-left-transition>
  </div>
</template>

<script>
import Calendar from "vue-calendar-component";
export default {
  components: { Calendar },
  data() {
    return {
      timeArr: ["2020/10/12", "2020/10/23"],
      selectDate: "",
      isShowSearch: false,
      currentSearchTime: "",
      details: [],
      currentTime: {
        time: ""
      }
    };
  },
  computed: {},
  methods: {
    //查看详情
    goDetails(item) {
      console.log(item);
    },
    //获取当前月份的访视
    async getReSearch(date) {
      let time = "";
      if (date) {
        //删除日期
        date = date.substring(0, date.lastIndexOf("/"));
        time = date;
      }
      if (!date) time = this.GMTToStr(new Date());
      try {
        let result = await this.$API.getTime(time);
        if (result.code === 200) {
          this.timeArr = result.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //GMT转时间格式
    GMTToStr(time) {
      let date = new Date(time);
      let Str = date.getFullYear() + "/" + (date.getMonth() + 1);
      return Str;
    },
    //普通转GMT
    StrToGMT(time) {
      let GMT = new Date(time);
      return GMT;
    },
    async clickDay(data) {
      if (this.timeArr.indexOf(data) > -1) {
        try {
          //转换为时间戳
          const time = new Date(data).getTime();
          console.log(time);
          let result = await this.$API.getTimeDetails(time);
          if (result.code === 200) {
            this.details = result.data;
            this.isShowSearch = true;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isShowSearch = false;
        this.details = [];
      }
    },
    changeDate(date) {
      this.isShowSearch = false;
      this.getReSearch(date);
    }
  },
  watch: {},

  async mounted() {
    this.$Token();
    this.getReSearch();
  }
};
</script>
<style lang="scss" scoped>
.myDate {
  background: #ededed;
  padding: 10px;
  .date {
    margin-bottom: 10px;
    border-radius: 10px;
  }
}
.details {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-bottom: 0px !important;
  h3 {
    text-align: left;
  }
  > p {
    text-align: left;
    margin-bottom: 10px !important;
    color: #838383;
  }
  .remind {
    display: flex;
    ul {
      li {
        position: relative;
        text-align: left;
        height: 25px;
        color: #2a3a51;
        line-height: 25px;
        padding-left: 30px !important;
        box-sizing: border-box;
        .menuIcon {
          position: absolute;
          left: 0;
          top: 2px;
        }
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }
  .epro {
    text-align: left;
    margin-bottom: 5px;
    color: #838383;
    span {
      margin-right: 10px;
      color: #2a3a51;
    }
  }
  .look {
    text-align: left;
    height: 40px;
    line-height: 40px;
    color: #838383;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .icon-xiangyou1 {
      font-size: 12px;
    }
  }
}
</style>
